export function forceFileDownload(blob, fileName) {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  document.body.appendChild(link);
  link.style = 'display: none';
  link.href = url;
  link.setAttribute('download', fileName);
  link.click();
  window.URL.revokeObjectURL(url);
}

export function getFileNameFromResponseHeaders(headers) {
  const contentDisposition = headers['content-disposition'];
  if (!contentDisposition) {
    return null;
  }
  const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i);
  if (!match) {
    return null;
  }
  const fileName = match[1];
  return fileName.replaceAll('"', '');
}
