<template>
  <div class="app-container">
    <h1>{{ $t('hermes.upload') }}</h1>
    <el-row :gutter="20">
      <el-col :span="4">
        <div>{{ $t('hermes.invoiceNumber') }}</div>
      </el-col>
      <el-col :span="6">
        <m-dinput @change="invoiceNumberChange"></m-dinput>
      </el-col>
      <el-col :span="6">
        <el-button @click="downloadExcelAfterConfirm">{{ $t('fileExport.generate') }}</el-button>
      </el-col>
    </el-row>
    <el-row v-if="invoiceFileName" :gutter="20">
      <el-col :span="4">
        <div>{{ $t('fileExport.invoiceFileName') }}:</div>
      </el-col>
      <el-col :span="6">
        {{ invoiceFileName }}
      </el-col>
    </el-row>
    <el-row v-if="surchargeFileName" :gutter="20">
      <el-col :span="4">
        <div>{{ $t('fileExport.surchargeFileName') }}:</div>
      </el-col>
      <el-col :span="6">
        {{ surchargeFileName }}
      </el-col>
    </el-row>
  </div>
</template>

<script>
import MDinput from '@/components/MDinput';
import crudIndexMixin from '@/mixins/crud-index';
import { forceFileDownload, getFileNameFromResponseHeaders } from '@/utils/fileDownload';
import { entityToStore } from '@/utils/store';

export default {
  components: { MDinput },
  mixins: [crudIndexMixin],
  data() {
    return {
      entity: 'Invoice',
      returnTo: 'Invoices',
      invoiceNumber: null,
      invoiceFileName: null,
      surchargeFileName: null,
      loading: false
    };
  },
  computed: {
    entityStore() {
      return entityToStore(this.entity);
    }
  },
  methods: {
    invoiceNumberChange(input) {
      this.invoiceNumber = input;
    },
    downloadExcelAfterConfirm() {
      this.invoiceFileName = null;
      this.surchargeFileName = null;
      this.$store
        .dispatch('notify/confirm', {
          message: this.$i18n.t('hermes.confirmDownload', { invoice: this.invoiceNumber }),
          title: this.$i18n.t('common.confirm'),
          type: 'success'
        })
        .then(() => {
          this.doDownloadExcel();
        })
        .catch(() => {
          console.log('export cancelled by user');
          this.fileName = '';
        });
    },
    async doDownloadExcel() {
      this.loading = true;
      this.$store
        .dispatch('invoice/generateExcelExport', this.invoiceNumber)
        .then(response => {
          this.invoiceFileName = getFileNameFromResponseHeaders(response.headers);
          forceFileDownload(response.data, this.invoiceFileName);
        })
        .finally(() => {
          this.loading = false;
        });
      this.loading = true;
      this.$store
        .dispatch('surcharge/generateExcelExport', this.invoiceNumber)
        .then(response => {
          this.surchargeFileName = getFileNameFromResponseHeaders(response.headers);
          console.log(this.surchargeFileName);
          forceFileDownload(response.data, this.surchargeFileName);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style>
.el-row {
  margin-bottom: 20px;
}
.el-row:last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
</style>
